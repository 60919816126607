

























































































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import useMessageTemplates from "@/use/messageTemplates";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
    MTestEmail: () => import("@/components/molecules/m-test-email.vue"),
  },

  setup(_, { root }) {
    const { shortLanguageItems } = useSelectItems({ root });
    const { fetchTemplates } = useMessageTemplates({ root });

    const model = reactive<{
      languageSwitch: string;
      polishTemplate: any;
      polishTitle: any;
      polishEditor: any;
      englishTemplate: any;
      englishTitle: any;
      englishEditor: any;
    }>({
      languageSwitch: "pl",
      polishTemplate: "",
      polishTitle: "",
      polishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      englishTemplate: "",
      englishTitle: "",
      englishEditor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
    });

    const state = reactive({
      templates: [],
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      polishEditorLoaded: false,
      englishEditorLoaded: false,
      polishTemplateAdded: {} as any,
      englishTemplateAdded: {} as any,
      eventName: "",
      dialog: false,
      event: {},
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const getTemplates = async () => {
      const data = await fetchTemplates("other");
      state.loading = data.loading;
      state.loaded = data.loaded;
      state.templates = data.templates;
    };

    onMounted(getTemplates);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (model.languageSwitch === "pl") {
        axiosInstance
          .get(`message-template/${model.polishTemplate}`)
          .then(({ data: { messageTemplate } }) => {
            model.polishEditor.design = messageTemplate?.design || undefined;
            model.polishTitle = messageTemplate?.title || undefined;
            state.polishEditorLoaded = true;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            state.loading = false;
          });
      } else if (model.languageSwitch === "en") {
        axiosInstance
          .get(`message-template/${model.englishTemplate}`)
          .then(({ data: { messageTemplate } }) => {
            model.englishEditor.design = messageTemplate?.design || undefined;
            model.englishTitle = messageTemplate?.title || undefined;
            state.englishEditorLoaded = true;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            state.loading = false;
          });
      }
    };

    watch(() => model.polishTemplate, fetchData);
    watch(
      () => model.polishTemplate,
      () => {
        if (state.polishEditorLoaded) {
          state.polishEditorLoaded = false;
          fetchData();
        }
      }
    );
    watch(() => model.englishTemplate, fetchData);
    watch(
      () => model.englishTemplate,
      () => {
        if (state.englishEditorLoaded) {
          state.englishEditorLoaded = false;
          fetchData();
        }
      }
    );

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (model.languageSwitch === "pl") {
        axiosInstance
          .get(`event/${root.$route.params.id}`)
          .then(({ data: { event } }) => {
            state.event = event;
            model.polishEditor.design =
              event.initAbstractAcceptationMail?.design || undefined;
            model.polishTitle =
              event.initAbstractAcceptationMail?.title || undefined;
            state.polishEditorLoaded = event.initAbstractAcceptationMail?.design
              ? true
              : false;
            state.polishTemplateAdded = event.initAbstractAcceptationMail;
            state.englishEditorLoaded = false;
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              state.templates = [];
            } else {
              console.log(error);
            }
            console.log(error);
          })
          .finally(() => {
            state.loaded = true;
            state.loading = false;
          });
      } else if (model.languageSwitch === "en") {
        axiosInstance
          .get(`event/${root.$route.params.id}`)
          .then(({ data: { event } }) => {
            state.event = event;
            model.englishEditor.design =
              event.initAbstractAcceptationMailOtherLanguage?.design ||
              undefined;
            model.englishTitle =
              event.initAbstractAcceptationMailOtherLanguage?.title ||
              undefined;
            state.englishEditorLoaded = event
              .initAbstractAcceptationMailOtherLanguage?.design
              ? true
              : false;
            state.englishTemplateAdded =
              event.initAbstractAcceptationMailOtherLanguage;
            state.polishEditorLoaded = false;
          })
          .catch((error) => {
            if (error.response?.status === 404) {
              state.templates = [];
            } else {
              console.log(error);
            }
          })
          .finally(() => {
            state.loaded = true;
            state.loading = false;
          });
      }
    };

    onMounted(fetchEvent);

    watch(() => model.languageSwitch, fetchEvent);

    const onSubmit = async (relation?: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      if (
        (model.languageSwitch === "pl" && state.polishTemplateAdded) ||
        (model.languageSwitch === "en" && state.englishTemplateAdded)
      ) {
        const data = {
          template:
            model.languageSwitch === "pl"
              ? model.polishEditor.html || undefined
              : model.englishEditor.html || undefined,
          design:
            model.languageSwitch === "pl"
              ? model.polishEditor?.design || undefined
              : model.englishEditor?.design || undefined,
          title:
            model.languageSwitch === "pl"
              ? model.polishTitle || undefined
              : model.englishTitle || undefined,
        };

        const id =
          model.languageSwitch === "pl"
            ? state.polishTemplateAdded?.id
            : state.englishTemplateAdded?.id;
        axiosInstance
          .put(`message/${id}`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("layout.misc.templateEdited"),
            });
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      } else {
        const data = {
          template:
            model.languageSwitch === "pl"
              ? model.polishEditor.html || undefined
              : model.englishEditor.html || undefined,
          design:
            model.languageSwitch === "pl"
              ? model.polishEditor?.design || undefined
              : model.englishEditor?.design || undefined,
          title:
            model.languageSwitch === "pl"
              ? model.polishTitle || undefined
              : model.englishTitle || undefined,
          relation: relation,
          eventId: root.$route.params.id,
        };
        axiosInstance
          .post("message", data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;
            state.error = false;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("layout.misc.templateSaved"),
            });
            fetchEvent();
          })
          .catch((error) => {
            state.error = error.response?.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    return { model, state, onSubmit, getErrorMessage, shortLanguageItems };
  },
});
